import { makeStyles } from '@material-ui/core/styles';

import Box from '@nfs/ui-kit/Box';
import Typography from '@nfs/ui-kit/Typography';

import Layout from '../components/layouts/basic';

const useStyles = makeStyles(theme => ({
    container: {
        height: theme.spacing(150)
    },
    row: {
        width: theme.spacing(150)
    }
}));

const ErrorPage = () => {
    const classes = useStyles();

    return (
        <Layout>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.container}
            >
                <Box className={classes.row}>
                    <Box mt={4}>
                        <Typography variant="h2" component="div" align="center">
                            Page Not Found
                        </Typography>
                    </Box>
                    <Box mt={4}>
                        <Typography variant="h6" component="div" align="center">
                            The page you requested may have been moved or
                            deleted.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Layout>
    );
};

export default ErrorPage;
